// imports
// import jQuery from "jquery";
// const $ = jQuery;
// import $ from "cash-dom";
import UIkit from "uikit"; // UIkit - core
import Icons from "uikit/dist/js/uikit-icons"; // UIkit - icons
UIkit.use(Icons); // UIkit - icons init

console.log("app.js loaded");

window.onload = function() {

    UIkit.util.on('#modal-media-youtube', 'shown', function () {
        // do something
        const iframeContainer = document.getElementById("iframe-container");
        const iframe = document.createElement("iframe");
        iframe.setAttribute("src", "https://www.youtube.com/embed/zZ5JOAhxLQQ");
        iframe.setAttribute("width", "560");
        iframe.setAttribute("height", "315");
        iframe.setAttribute("frameborder", "0");
        iframe.setAttribute(
            "allow",
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        );
        iframe.setAttribute("allowfullscreen", "");
        iframeContainer.appendChild(iframe);
    });
}


